<template>
  <el-tabs v-model="activeName" type="card">
    <el-tab-pane label="加收申请单" name="second">
      <div class="headerbut">
        <el-button class="seniorbut" @click="SubmitBut">提交</el-button>
        <el-button class="ordinarybut" @click="eliminate">取消</el-button>
      </div>
      <div class="box2">
        <div class="queryItem1">
          <div class="queryLabel FontStyle">加收编号:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.otherFinanceNumber"
              :disabled="true"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">来源单号:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.documentNumber"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1 curPo" @click="huiyuanbut">
          <div class="queryLabel FontStyle">会员号:</div>
          <div class="right_inpu">
            <el-input
              v-model="Membernumber"
              placeholder="请点击选择会员"
              readonly
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1 curPo" @click="huiyuanbut">
          <div class="queryLabel FontStyle">会员名称:</div>
          <div class="right_inpu">
            <el-input
              v-model="MemberName"
              placeholder="请点击选择会员"
              readonly
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">来源单据:</div>
          <div class="right_inpu">
            <el-select
              v-model="arraydata.financeDocumentstatusId"
              @change="danjubut"
              class="queryInput"
            >
              <el-option
                v-for="(item, index) in danjuList"
                :label="item.documentStatusName"
                :value="item.financeDocumentstatusId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>

        <!-- <div class="queryItem1">
          <div class="queryLabel FontStyle">
            会员名称:
          </div>
          <div class="right_inpu">
            <el-input v-model="arraydata.documentNumber" class="queryInput"></el-input>
          </div>
        </div> -->
        <div class="queryItem1">
          <div class="queryLabel FontStyle">加收项目:</div>
          <div class="right_inpu">
            <el-select
              v-model="arraydata.itemServiceId"
              @change="choice"
              class="queryInput"
            >
              <el-option
                v-for="(item, index) in feixiangList"
                :label="item.title"
                :value="item.itemServiceId"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">金额:</div>
          <div class="right_inpu">
            <el-input
              v-model="arraydata.settlementAmount"
              class="queryInput"
            ></el-input>
          </div>
        </div>
        <div class="queryItem1">
          <div class="queryLabel FontStyle">收支备注:</div>
          <div class="right_inpu">
            <el-input v-model="arraydata.comment" class="queryInput"></el-input>
          </div>
        </div>
      </div>
      <el-dialog
        title="会员管理"
        :visible.sync="isDT"
        width="50%"
        :close-on-click-modal="false"
      >
        <div class="newForm">
          <div class="formS">
            <el-form
              ref="vf"
              size="mini"
              :model="vf"
              :rules="vfr"
              label-width="80px"
            >
              <el-row>
                <el-col :span="19" style="display: flex">
                  <div class="queryItem2">
                    <el-form-item label="会员号:" prop="memberId">
                      <el-input v-model="vf.memberId"></el-input>
                    </el-form-item>
                  </div>
                  <div class="queryItem2">
                    <el-form-item label="会员名称:" prop="nickname">
                      <el-input v-model="vf.nickname"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="1">
                  <el-form-item label-width="10px">
                    <el-button
                      type="warning"
                      style="margin-top: 3px"
                      @click="cxEdit"
                      >查 询</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tableBox">
            <el-table
              :data="ut"
              size="mini"
              max-height="400px"
              border
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column
                prop="memberId"
                label="会员号"
                align="center"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="会员名称"
                align="center"
                min-width="120"
              >
              </el-table-column>

              <el-table-column
                fixed="right"
                label="操作"
                align="center"
                min-width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: #ef7c1b"
                    size="mini"
                    @click="xz(scope.row)"
                    >选 择
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页组件 -->
            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="sizeList"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-dialog>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      activeName: "second",
      input: "",
      arraydata: {
        documentNumber: "", //来源单号单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        storageId: "", //仓库id
        itemServiceId: "", //费项id
        serviceName: "", //名称
        userId: "", //会员id
      },
      Membernumber: "", //会员号
      MemberName: "", //会员名称
      danjuList: [], //来源单据列表
      feixiangList: [], //费项目列表
      pageType: "add", //新增还是修改
      nid: "", //修改传过来的id

      //会员选择
      isDT: false,
      vf: {},
      vfr: {},
      ut: [],
      pageNum: 1,
      total: 0,
      size: 20,
      sizeList: [10, 20, 50, 100, 200],
      isJunp: false, //  页面是否要跳转标识
      empty: false, //拼团
      Collage: false, //拼团
      form: {
        name: "",
        delivery: false,
      },
      packageId: undefined,
      NotOptional: true, //金额是否能修改
    };
  },
  created() {},
  activated() {
    this.pageType = this.$route.query.pageType || "add";
    if (this.pageType == "add") {
      this.eliminate();
    }
    this.nid = this.$route.query.nid;
    if (this.nid) {
      this.otherFinanceOutDetail();
    }
  },
  mounted() {
    this.itemServiceListAll();
    this.getStatusValList();
  },
  methods: {
    //费项下拉数据 收
    itemServiceListAll() {
      Api.itemServiceListAll({ directionStatus: "shou:direction_status" }).then(
        (res) => {
          this.feixiangList = res.data.result;
          //console.log(res.data.result)
        }
      );
    },
    //来源单据下拉列表
    getStatusValList() {
      Api.financeDocumentstatusChooseList().then((res) => {
        this.danjuList = res.data.result;
        //console.log(res.data.result)
      });
    },
    //单据下拉选择
    danjubut(e) {
      let nid = e - 1;
      this.arraydata.financeDocumentstatusName =
        this.danjuList[nid].documentStatusName;
      //console.log(this.danjuList[nid].documentStatusName)
    },
    //加收下拉选择
    choice(e) {
      let nid = e - 1;
      this.arraydata.settlementAmount = this.feixiangList[nid].unitPrice;
      this.arraydata.serviceName = this.feixiangList[nid].title;
      //console.log(this.feixiangList[nid].unitPrice)
    },
    //客户加收获取详情
    otherFinanceOutDetail() {
      let nid = this.nid;
      Api.customerAdditionalDetail({ otherFinanceId: nid }).then((res) => {
        let array = res.data.result;
        this.arraydata.otherFinanceNumber = array.otherFinanceNumber; //加收编号
        this.arraydata.documentNumber = array.documentNumber; //来源单号单号 必填
        this.arraydata.financeDocumentstatusId = array.financeDocumentstatusId; //冲销单据id
        this.arraydata.financeDocumentstatusName =
          array.financeDocumentstatusName; //冲销单据名称
        this.arraydata.settlementAmount = array.settlementAmount; //结算金额
        this.arraydata.comment = array.comment; //备注
        this.arraydata.storageId = array.storageId; //仓库id
        this.Membernumber = array.memberId; //会员号
        this.MemberName = array.nickname; //会员名称
        this.arraydata.itemServiceId = array.itemServiceId; //费项id
        this.arraydata.serviceName = array.serviceName; //名称
        this.arraydata.userId = array.userId; //会员id
        this.NotOptional = array.approveStatus;
        //this.NotOptional='shen_pi:approve_status' ? true : false
        if (this.NotOptional == "shen_pi:approve_status") {
          this.NotOptional = false;
        } else {
          this.NotOptional = true;
        }
        //console.log(this.NotOptional)
      });
    },

    //提交
    SubmitBut() {
      // let arraydata = this.arraydata;
      // if (this.nid) {
      //   arraydata.otherFinanceId = this.nid;
      // }
      let arraydata = {
        financeDocumentstatusId: this.arraydata.financeDocumentstatusId,
        financeDocumentstatusName: this.arraydata.financeDocumentstatusName,
        settlementAmount: this.arraydata.settlementAmount,
        userId: this.arraydata.userId,
        comment: this.arraydata.comment,
        documentNumber: this.arraydata.documentNumber,
        itemServiceId: this.arraydata.itemServiceId,
        serviceName: this.arraydata.serviceName,
        storageId: this.arraydata.storageId,
      };

      if (this.pageType == "add") {
        let sign = tools.getSign(arraydata);
        arraydata.sign = sign;
        Api.addCustomerAdditional(arraydata).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.eliminate();
            tools.closePage();
            this.reload();
          }
        });
      } else {
        arraydata.otherFinanceId = this.nid || "";
        // let sign = tools.getSign(arraydata);
        // arraydata.sign = sign;
        Api.updateCustomerAdditional(arraydata).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            this.eliminate();
            tools.closePage();
            this.reload();
          }
        });
      }
    },
    //取消
    eliminate() {
      this.arraydata = {
        documentNumber: "", //来源单号单号 必填
        financeDocumentstatusId: "", //冲销单据id
        financeDocumentstatusName: "", //冲销单据名称
        settlementAmount: "", //结算金额
        comment: "", //备注
        storageId: "", //仓库id
        itemServiceId: "", //费项id
        serviceName: "", //名称
        userId: "", //会员id
      };
      this.Membernumber = ""; //会员号
      this.MemberName = ""; //会员名称
    },
    //会员点击弹窗
    huiyuanbut() {
      this.isDT = true;
      this.cxEdit();
    },
    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.pageNum;
      this.vf.pageTotal = this.size;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          //console.log(res.data.result.data)
          this.ut = res.data.result.data;
          this.total = res.data.result.pageCount || 0;
        }
      });
    },

    xz(data) {
      //  会员表格选择事件
      this.Membernumber = data.memberId;
      this.MemberName = data.nickName;
      this.arraydata.userId = data.userId;
      console.log(data);
      this.isDT = false;
    },
    cxEdit() {
      //  查询按钮
      this.pageNum = 1;
      this.getVipList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getVipList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getVipList();
    },
  },
};
</script>
<style scoped lang="scss">
.headerbut {
  display: flex;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.panel {
  width: 100%;
  margin: 20px auto;
  padding: 100px 20px;
  box-sizing: border-box;
  border: 1px solid slategrey;
}

.box2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px #e0e0e0;
  padding: 20px;
  width: 99.5%;
  margin: 0 auto;
  display: flex;
  margin: 10px auto;
  box-sizing: border-box;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  .remarksbox {
    color: #606266;
    width: 50%;
    display: flex;
    padding: 5px 1%;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    .remarkstitle {
      width: 7%;
      line-height: 30px;
    }
    .remarksinp {
      width: 50%;
    }
  }
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
//能输入样式
.queryItem1 {
  min-width: 455px;
  width: 30%;
  margin: 3px 1%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;

  align-items: center;
  font-size: 14px;
  .FontStyle {
    color: #f00;
    cursor: pointer;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .queryLabel {
    width: 100px;
    min-width: 100px;
    color: #606266;
    text-align: right;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .right_inpu_post {
    position: relative;
    .niming {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99;
    }
  }
  .right_inpu {
    width: 100%;
  }
  .right_inpu ::v-deep {
    .el-input__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 100%;
    }
  }
  .right_inpu ::v-deep {
    .el-textarea__inner {
      min-height: 28px;
      height: 28px;
      line-height: 28px;
      border: 0;
      width: 375px;
    }
  }
}
.redstyle {
  color: #f00 !important;
}
.formS {
  margin-bottom: 20px;
}
.queryItem2 {
  width: 50%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  margin: 3px 1%;
  align-items: center;
  font-size: 13px;
}
.queryItem1:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}

.curPo {
  ::v-deep {
    .el-input__inner {
      cursor: pointer;
    }
  }
}
</style>